/* Define Variables */
/* TODO consider light mode and moving these out (importing as needed or making css objects) */

:root {

  --scrollbarBG: #1F1C20;
  --thumbBG: #110F12;

  --fg-color: #110F12;
  --fg-border-color: rgba(82, 60, 92, 0.2);

  --mg-color: rgb(23, 21, 24);
  --mg-border-color: rgb(37, 34, 39);

  --bg-color: #1F1C20;
  --bg-border-color: ;

  --code-bg-color: rgb(23, 23, 29);

  --font-color: ghostwhite;

  --link-color: white;
  --link-hover-color: lightblue;

  --bread-color: rgb(138, 170, 190);
  --bread-delim-color: rgba(106, 131, 146, 0.555);
}


/*
TODO
get rid of all framing under 600 px like bootstrap
this is to help when using as reference in sized down window
no absolute header no sidebar no absolute footer
make header sizes responsive
 */

/* scrollbar - https://css-tricks.com/the-current-state-of-styling-scrollbars/*/
.content::-webkit-scrollbar {
  width: 11px;
}
.content {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.content::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}
.content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--fg-border-color);;
}
/**/
html body {
  margin: 0;
  padding: 0;
  background: var(--mg-color);
  z-index: 1;
  position: absolute;
  height:100%;
  width: 100%;
  color: var(--font-color);;
  font-family: 'Ubuntu Mono';
  overflow:hidden;
}

.title {
  user-select: none;
  cursor: arrow;
}
.title h1 {
  font-size: 3rem;
  display: inline-block;
  color: var(--font-color);
}
.title h6 {
  padding-top: 25px;
  display: inline-block;
  color: var(--font-color);
}

.title svg
{
  fill: var(--font-color);
  font-size: 2.5rem;
  transform: translate(3px, 8px);
}

.main {
  background: var(--bg-color);

  border-radius:10px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--mg-border-color);

  overflow:hidden;

  position: absolute;
  top: 75px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  transition: all .25s ease-out;
}

@media (min-width: 68px) {
  .content-area {
    width: calc(100% - 40px);
    left: 20px;
  }
}

@media (min-width: 768px) {
  .content-area {
    /* left: 7%;
    width: 90%; */
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: var(--mg-border-color);
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: var(--mg-border-color);
    width: calc(100% - 100px);
    left: 50px;
  }
}
@media (min-width: 992px) {
  .content-area {
    /* left: 5%;
    width: 90%; */
    /* left: 15%;
    width: 80%; */
    width: calc(100% - 160px);
    left: 80px;
  }
}

/* settings becomes modal below this size */
@media (min-width: 1275px) {
  .content-area {
    left: 200px;
    width: calc(100% - 450px);
  }
}

@media (min-width: 1500px) {
  .content-area {
    left: 250px;
    width: calc(100% - 550px);
  }
}

.content-area {

  position:absolute;
  top:75px;
  bottom: 39px;
  padding: 20px;
  overflow: auto;
  padding-bottom: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.header {
  height: 75px;
}

.footer-area {
  background: var(--mg-color);
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 1px;
  z-index: 2;
  overflow: hidden;
  font-size: 110%;
}

.footer-info {
  padding: 0;
  padding-left:45px;
  display: inline-block;
}
.footer-center {
  padding: 0;
  display: inline-block;
}
.footer-icons {
  padding: 0;
  padding-right: 25px;
  display: inline-block;
}

.footer-icons svg {
  margin-left: 10px;
}

a svg {
  fill: var(--link-color);
  transition: .5s ease-in-out;
}
a svg:hover {
  fill: var(--link-hover-color);
}
